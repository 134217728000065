
.pageContainer {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 36px 18px;
    overflow: scroll;
    overflow-x: hidden;


}

.pageHeader {
    text-align: left;
    padding: 0 0 30px 0 ;
    margin: 0;
    color: #6B6B6B;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #AFACACCC;
    border-bottom: 1px solid #AFACACCC;
    padding: 25px 0 25px 0 ;
    margin-bottom: 25px;
}

.filterContainer {
    margin-left: 100px;
    gap: 20px;
    align-items: center;
    justify-content: start;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.modal {
    background: #D9D9D9;
    padding: 36px;
    position: relative;
    inset: auto;
    margin: auto;
    box-sizing: border-box;
    z-index: 1;
    border-radius: 25px;
    width: 360px;
    border: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.button {
    display: flex;
    align-items: center;
    background: #B7B5B5;
    border-radius: 10px;
    padding: 0 20px;
}

.button:hover {
    cursor: pointer;
    background: #999999;
}

.button:active {
    scale: 0.9;
    background: #999999;
}