
.footerContainer {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    gap: 24px;
}


.crossImage {
    width: 24px;
    height: 24px;
}

.socialsContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.social:hover {
    cursor: pointer;
}

.social:active {
    scale: 0.95;
}

.logo {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    padding: 80px 0 0 100px;
}

.logoImage {
    width: 250px;
}

.backgroundContainer {
    display: flex;
    position: sticky;
    z-index: 1;
    left: 0;
    top: -50vh;
    width: 600px;
    height: 120px;
}

.backgroundImage {
    position: absolute;
    height: 2313px;
}

.backgroundImageSuccess {
    position: absolute;
    height: 1000px;
}

.mainContainer {
    display: flex;
    position: relative;
    left: 10%;
    z-index: 1000;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 736px;
    padding: 120px 40px 0 35%;
}

.header {
    line-height: 72px;
    font-size: 60px;

    align-self: flex-start;
    text-align: left;
    font-family: Pragmatica, sans-serif; /* Fallback font */
    font-weight: lighter;
    color: #000000;
    margin: 0;
}

.secondHeader {
    width: 100%;
    line-height: 26px;
    font-size: 20px;
    align-self: flex-start;
    margin-top: 40px;
    margin-bottom: 8px;
    text-align: left;
    font-family: Pragmatica, sans-serif; /* Fallback font */
    font-weight: 400;
    color: #000000;
}

.questionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.questionHeader {
    width: 90%;
    line-height: 38px;
    font-size: 28px;
    align-self: flex-start;
    text-align: left;
    font-family: Manrope, sans-serif; /* Fallback font */
    font-weight: 700;
    color: #3F444F;
    margin: 72px 0 32px 0;
}

.commentHeader {
    width: 100%;
    line-height: 26px;
    font-size: 20px;
    margin: 0;
    align-self: flex-start;
    padding: 40px 0 12px 0;
    text-align: left;
    font-family: Manrope, sans-serif; /* Fallback font */
    font-weight: 400;
    color: #3F444F;
}

.roundButton {
    border-radius: 100%;
    border: 1px solid #3F444F;
    background-color: white;
    width: 100%;
    font-size: 24px;
    font-family: Manrope, sans-serif; /* Fallback font */
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1; /* Сохраняет пропорции */
}

.roundButton:hover {
    cursor: pointer;
    background-color: #3F444F;
    color: white
}

.roundButtonPushed {
    border-radius: 100%;
    border: 1px solid #9C162E;
    background-color: #9C162E;
    color: white;
    cursor: default; /* Prevent pointer change */
    width: 100%;
    font-size: 24px;
    font-family: Manrope, sans-serif; /* Fallback font */
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1; /* Сохраняет пропорции */
}

.buttonContainer {
    display: flex;
    justify-content: start;
    gap: 12px;
    margin-bottom: 20px;
    width: 100%;
}


.buttonSign {
    color: #8C8F95;
    margin: 0;
    font-size: 18px;
    line-height: 25px;
    font-family: Manrope, sans-serif; /* Fallback font */
}

.buttonSignContainer {
    display: flex;
    justify-content: space-between;
}

.textArea {
    width: 100%;
    height: 200px;
    box-sizing: border-box;
    font-size: 16px;
    padding: 20px;
    font-family: "Manrope", sans-serif;
    margin-bottom: 20px;
    border: 1px solid #C6C7CB;
    border-radius: 2.65px;
}

.textArea::placeholder {
    font-family: "Manrope", sans-serif;
}

.textArea::placeholder {
    color: #8C8F95;
}

.submitButton {
    padding: 18px 64px;
    background-color: #9C162E;
    border: unset;
    border-radius: 64px;
}

.submitButton:disabled {
    background: #C6C7CB;
}

.submitButtonActive:active {
    scale: 0.95;
}

.submitButtonActive:hover {
    background: #AA001E;
}

.submitButtonText {
    font-size: 28px;
    font-family: Pragmatica, sans-serif; /* Fallback font */
    color: #FFFFFF;
    margin: 0;
}

.closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: transparent;
    font-size: 24px;
    cursor: pointer;
}

.modalHeader {
    font-size: 38px;
    font-family: Manrope, sans-serif; /* Fallback font */
    width: 70%;
    text-align: center;
}

.okButton {
    position: relative;
    z-index: 5;
    padding: 18px 64px;
    background-color: #9C162E;
    border: unset;
    border-radius: 64px;
    font-size: 28px;
    font-family: Pragmatica, sans-serif; /* Fallback font */
    color: #FFFFFF;
    margin-top: 20px;
    cursor: pointer;
}

.okButton:active {
    scale: 0.95;
}

.okButton:hover {
    background: #e7183d;
}

.submitButtonContainer {
    display: flex;
    justify-content: start;
    width: 100%;
    padding-top: 28px;
    margin-bottom: 200px;
}

.footerText {
    font-size: 15px;
    margin: 0;
}

.modal {
    background: white;
    position: relative;
    inset: auto;
    margin: auto;
    z-index: 4000;
    width: 800px;
    border-radius: 25px;
    border: unset;
    height: 450px;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media(max-width: 1920px) and (min-width: 1600px) {
    .backgroundImage {
        left: -25%;
        height: 2190px;
    }

    .backgroundImageSuccess {
        left: 0;
        height: 1000px;
    }
}

@media(max-width: 1600px) and (min-width: 1280px) {
    .backgroundImage {
        left: -25%;
        height: 2021px;
    }

    .mainContainer {
        left: 0;
    }

    .backgroundImageSuccess {
        left: -10%;
        height: 1000px;
    }
}

@media(max-width: 1280px) and (min-width: 1024px) {

    .logo {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        padding: 70px 0 0 100px;
    }

    .logoImage {
        width: 166px;
    }

    .backgroundImage {
        left: -65%;
        height: 2021px;
    }

    .mainContainer {
        left: 0;
        width: 618px;
    }

    .header {
        font-size: 52px;
    }

    .secondHeader {
        font-size: 18px;
        line-height: 23.4px;
        margin-top: 32px;
        margin-bottom: 4px;
    }

    .buttonContainer {
        margin-bottom: 16px;
    }

    .questionHeader {
        margin-top: 60px;
    }

    .backgroundImageSuccess {
        left: -10%;
        height: 800px;
    }
}

@media(max-width: 1024px) and (min-width: 768px) {

    .logo {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        padding: 40px 0 0 40px;
    }

    .logoImage {
        width: 123px;
    }

    .backgroundImage {
        left: -75%;
        height: 2021px;
    }

    .mainContainer {
        left: 0;
        width: 618px;
        padding: 120px 40px 0 25%;
    }

    .header {
        font-size: 52px;
    }

    .secondHeader {
        font-size: 18px;
        line-height: 23.4px;
        margin-top: 32px;
        margin-bottom: 4px;
    }

    .buttonContainer {
        margin-bottom: 16px;
    }

    .questionHeader {
        margin-top: 60px;
    }
    .backgroundImageSuccess {
        left: -10%;
        height: 800px;
    }
}

@media(max-width: 850px) and (min-width: 768px) {

    .mainContainer {
        left: 0;
        width: 618px;
        padding: 120px 40px 0 15%;
    }
    .backgroundImageSuccess {
        left: -15%;
        height: 800px;
    }

}
/*@media(max-width: 1400px) and (min-width: 600px){*/

/*    .backgroundContainer {*/
/*        top: 30vh;*/
/*    }*/
/*    .backgroundImage {*/
/*        height: 70vh;*/
/*    }*/
/*}*/

@media(max-width: 768px) and (min-width: 600px) {

    .logo {
        padding: 21px 30px;
        width: 140px;
    }

    .logoImage {
        width: 140px;
    }

    .backgroundContainer {
        display: none;
    }

    .backgroundImage {
        display: none;
    }

    .mainContainer {
        left: 0;
        width: unset;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 120px 40px 0 40px;
    }

    .header {
        width: 90%;
        font-size: 40px;
        line-height: 48px;
    }

    .secondHeader {
        width: 80%;
        line-height: 19px;
        font-size: 14px;
        margin-top: 28px;
        margin-bottom: -28px;
    }

    .questionHeader {
        line-height: 28px;
        font-size: 20px;
        width: 100%;
        font-weight: 800;
        margin: 72px 0 18px 0;
    }

    .rangeContainer {
        padding: 0 20px;
    }

    /*.roundButton {*/
    /*    border-radius: 26.53px;*/
    /*    border: 1px solid #3F444F;*/
    /*    width: 43px;*/
    /*    height: 36px;*/
    /*    font-size: 14px;*/
    /*    font-family: Manrope, sans-serif; !* Fallback font *!*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*}*/

    /*.buttonContainer {*/
    /*    display: flex;*/
    /*    grid-template-columns: repeat(6, 1fr);*/
    /*    gap: 9px 10px;*/
    /*    margin-bottom: 12px;*/
    /*    flex-wrap: wrap;*/
    /*    justify-content: start;*/
    /*}*/

    /*.buttonContainer {*/
    /*    display: flex;*/
    /*    justify-content: start;*/
    /*    gap: 9px 10px;*/
    /*    grid-template-columns: repeat(6, auto);*/
    /*    margin-bottom: 12px;*/
    /*    width: 100%;*/
    /*}*/

    /*.roundButton {*/
    /*    border-radius: 26.53px;*/
    /*    border: 1px solid #3F444F;*/
    /*    aspect-ratio: 43 / 36; !* Сохраняет пропорции *!*/
    /*}*/
    .buttonContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr); /* 6 колонок в первой строке */
        gap: 9px 10px; /* Задаем нужный gap между кнопками */
        max-width: 100%; /* Используем всю доступную ширину */
        margin-bottom: 12px;
    }

    .roundButton {
            font-size: 4vw;
            border-radius: 13.5vw;
            border: 1px solid #3F444F;
            aspect-ratio: 43 / 36; /* Сохраняет пропорции */
    }

    /*!* Вторая строка начинается с 7-й кнопки *!*/
    /*.buttonContainer > .roundButton:nth-child(7) ~ .roundButton {*/
    /*    grid-column: span 1; !* Каждая кнопка занимает одну колонку *!*/
    /*}*/

    /* Добавляем пустую колонку перед 7-й кнопкой для выравнивания */
    .buttonContainer > .roundButton:nth-child(7) {
        grid-column-start: 1; /* 7-я кнопка начинается со 2-й колонки */
    }


    .buttonSign {
        font-size: 8px;
        line-height: 11px;
    }

    .buttonSignContainer {
        margin-bottom: 15.8px;
    }

    .commentHeader {
        font-size: 18px;
        line-height: 24px;
    }
    .textArea {
        width: 100%;
        height: 110px;
        box-sizing: border-box;
        font-size: 14px;
        padding: 8px 12px;
        margin: 0;
        border: 1px solid #C6C7CB;
        border-radius: 2.65px
    }

    .questionContainer {
        padding-top: 40px;
        margin-bottom: 0;
    }

    .submitButtonText {
        font-size: 16px;
    }

    .submitButton {
        padding: 20px 45px;
    }

    .modalHeader {
        font-size: 14px;
        line-height: 19px;
        margin-top: 38px;
        margin-bottom: 33px;
        width: 80%
    }

    .okButton {
        padding: 12px 40px;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 20px;
        cursor: pointer;
    }

    .closeButton {
        top: 5px;
        right: 5px;
        font-size: 18px;
    }
    .submitButtonContainer {
        justify-content: center;
        margin-bottom: 48px;
    }

    .footerContainer {
        gap: 8px;
    }

    .footerText {
        font-size: 10px;
    }

    .socialsContainer {
        gap: 9px;
    }

    .social {
        width: 35px;
        height: 35px;
    }

    .socialImage {
        width: 35px;
        height: 35px;
    }

    .modal {
        top: -20%;
        width: 300px;
        border-radius: 9px;
        height: 170px;
    }

    .buttonSignContainer {
        padding: 0 20px;
    }

    .crossImage {
        width: 14px;
        height: 14px;
    }
}


@media(max-width: 600px) {

    .logo {
        padding: 24px 30px;
        width: 140px;
    }

    .logoImage {
        width: 140px;
    }

    .backgroundContainer {
        display: none;
    }

    .backgroundImage {
        display: none;
    }

    .mainContainer {
        left: 0;
        width: unset;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 104px 30px 0 30px;
    }

    .header {
        width: 100%;
        font-size: 24px;
        line-height: 29px;
    }

    .secondHeader {
        width: 80%;
        line-height: 19px;
        font-size: 14px;
        margin-top: 24px;
        margin-bottom: -28px;
    }

    .questionHeader {
        line-height: 24.5px;
        font-size: 18px;
        width: 100%;
        font-weight: 800;
        margin: 72px 0 18px 0;
    }

    .rangeContainer {
        padding: 0 20px;
    }

    /*.roundButton {*/
    /*    border-radius: 26.53px;*/
    /*    border: 1px solid #3F444F;*/
    /*    width: 43px;*/
    /*    height: 36px;*/
    /*    font-size: 14px;*/
    /*    font-family: Manrope, sans-serif; !* Fallback font *!*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*}*/

    /*.buttonContainer {*/
    /*    display: flex;*/
    /*    grid-template-columns: repeat(6, 1fr);*/
    /*    gap: 9px 10px;*/
    /*    margin-bottom: 12px;*/
    /*    flex-wrap: wrap;*/
    /*    justify-content: start;*/
    /*}*/

    /*.buttonContainer {*/
    /*    display: flex;*/
    /*    justify-content: start;*/
    /*    gap: 9px 10px;*/
    /*    grid-template-columns: repeat(6, auto);*/
    /*    margin-bottom: 12px;*/
    /*    width: 100%;*/
    /*}*/

    /*.roundButton {*/
    /*    border-radius: 26.53px;*/
    /*    border: 1px solid #3F444F;*/
    /*    aspect-ratio: 43 / 36; !* Сохраняет пропорции *!*/
    /*}*/
    .buttonContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr); /* 6 колонок в первой строке */
        gap: 9px 10px; /* Задаем нужный gap между кнопками */
        max-width: 100%; /* Используем всю доступную ширину */
        margin-bottom: 12px;
    }

    .roundButton {
        font-size: 4vw;
        border-radius: 13.5vw;
        border: 1px solid #3F444F;
        aspect-ratio: 43 / 36; /* Сохраняет пропорции */
    }

    /*!* Вторая строка начинается с 7-й кнопки *!*/
    /*.buttonContainer > .roundButton:nth-child(7) ~ .roundButton {*/
    /*    grid-column: span 1; !* Каждая кнопка занимает одну колонку *!*/
    /*}*/

    /* Добавляем пустую колонку перед 7-й кнопкой для выравнивания */
    .buttonContainer > .roundButton:nth-child(7) {
        grid-column-start: 1; /* 7-я кнопка начинается со 2-й колонки */
    }


    .buttonSign {
        font-size: 8px;
        line-height: 11px;
    }

    .buttonSignContainer {
        margin-bottom: 15.8px;
    }

    .commentHeader {
        font-size: 14px;
        line-height: 19px;
    }
    .textArea {
        width: 100%;
        height: 110px;
        box-sizing: border-box;
        font-size: 14px;
        padding: 8px 12px;
        margin: 0;
        border: 1px solid #C6C7CB;
        border-radius: 2.65px
    }

    .questionContainer {
        padding-top: 0;
        margin-bottom: 0;
    }

    .crossImage {
        width: 14px;
        height: 14px;
    }
    .submitButtonText {
        font-size: 16px;
    }

    .submitButton {
        padding: 20px 45px;
    }

    .modalHeader {
        font-size: 14px;
        line-height: 19px;
        margin-top: 38px;
        margin-bottom: 33px;
        width: 80%
    }

    .okButton {
        padding: 12px 40px;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 20px;
        cursor: pointer;
    }

    .closeButton {
        top: 5px;
        right: 5px;
        font-size: 18px;
    }
    .submitButtonContainer {
        justify-content: center;
        margin-bottom: 48px;
    }

    .footerContainer {
        gap: 8px;
    }

    .footerText {
        font-size: 10px;
    }

    .socialsContainer {
        gap: 9px;
    }

    .social {
        width: 35px;
        height: 35px;
    }

    .socialImage {
        width: 35px;
        height: 35px;
    }

    .modal {
        top: -20%;
        width: 300px;
        border-radius: 9px;
        height: 170px;
    }

    .buttonSignContainer {
        padding: 0 20px;
    }
}