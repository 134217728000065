
.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

}

.active {
    display: flex;
    width: 100%;
    padding: 0 20px;
    justify-content: start;
    border-left: 6px solid #6B6B6B;
    color: #999999;
    font-size: 22px;
}

.inactive {
    display: flex;
    width: 100%;
    padding: 0 20px;
    justify-content: start;
    color: #6B6B6B;
    font-size: 22px;
}