@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');


@font-face {
  font-family: 'Pragmatica';
  src: local('Pragmatica Book'), local('Pragmatica-Book'),
  url('fonts/Pragmatica-Book/Pragmatica-Book.woff2') format('woff2'),
  url('fonts/Pragmatica-Book/Pragmatica-Book.woff') format('woff'),
  url('fonts/Pragmatica-Book/Pragmatica-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
html {
  overflow: hidden;
  height: 100%;
}
body {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.App {
  text-align: center;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #5d5d5d;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.textArea::placeholder {
  color: #8C8F95;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

p {
  font-size: 22px;
}

.active:active {
  scale: 0.95;
}

.active:hover {
  background: #e7183d;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
}

.select {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Manrope', serif;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #323232;
  border-color: #323232;
}

.ant-select-dropdown {
  position: relative;
  z-index: 5000;
}

.ant-select-selection__placeholder {

}

@media(max-width: 600px) {
  .closeButton {
    top: 5px;
    right: 5px;
    font-size: 18px;
  }
}

/*#root > div > div > div > main > div > div.ant-table-wrapper.css-dev-only-do-not-override-11xg00t > div > div > div > div > div > table > tbody > tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > td > div > div > div > div > div > div > div > table > tbody > tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > td {*/
/*  padding: 0 !important;*/
/*}*/
/*#root > div > div > div > main > div > div.ant-table-wrapper.css-dev-only-do-not-override-11xg00t > div > div > div > div > div > table > tbody > tr > td {*/
/*  padding: 0 !important;*/

/*}*/

/*#root > div > div > div > main > div > div.ant-table-wrapper.css-dev-only-do-not-override-11xg00t > div > div > div > div > div > table > tbody > tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > td {*/
/*  padding: 0 !important;*/
/*}*/