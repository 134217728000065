
.logo {
    position: relative;
    display: flex;
    justify-content: start;
    top: 0;
    left: 0;
    gap: 20px;
    padding: 20px 30px 100px 20px;
}

.logoText {
    line-height: 55px;
    font-size: 40px;
    font-weight: 600;
    margin: 0;
}

.logoImage {
    display: flex;
    width: 60px;
    height: 60px;
}