
.buttonContainer {
    display: flex;
    align-items: center;
    background: #B7B5B5;
    border-radius: 10px;
    padding: 0 20px;
    /*noinspection CssInvalidPropertyValue*/
    text-wrap: nowrap;
    height: fit-content;
}

.buttonContainer:hover {
    cursor: pointer;
    background: #999999;
}

.buttonContainer:active {
    scale: 0.9;
    background: #999999;
}